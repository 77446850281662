<template>
  <div :class="['content-container', isAuth ? 'content-container--auth' : '']">
    <BaseH1 :text="'Weryfikacja nie odbyła się'" :short="true" class="title" />
    <SvgIllustrationsCross />
    <TextDescription v-html="description" />
    <ButtonLink
      v-if="isAuth"
      @click="resendVerificationLink"
      :label="'Wyślij link ponownie'"
    />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ROUTE_EMAIL_VERIFICATION_SEND_LINK_NAME } from "@/router/constants";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import useEmailVerification from "@/composables/useEmailVerification";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import SvgIllustrationsCross from "@/components/Svg/Illustrations/SvgIllustrationsCross.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";

const ROUTE_SEND_VERIFICATION_LINK = {
  name: ROUTE_EMAIL_VERIFICATION_SEND_LINK_NAME,
};

export default {
  components: {
    BaseH1,
    TextDescription,
    SvgIllustrationsCross,
    ButtonLink,
  },

  setup() {
    const userStore = useUserStore();
    const { isAuth } = storeToRefs(userStore);

    const description = computed(() => {
      if (!isAuth.value) {
        return "Niestety, ten link weryfikacyjny \njuż wygasł. \nZaloguj się, \naby wysłać nowy link";
      }
      return "Niestety, ten link weryfikacyjny \njuż wygasł. \nKliknij przycisk poniżej, \naby wysłać nowy link";
    });

    const { sendEmailVerificationLink } = useEmailVerification();
    const router = useRouter();
    const resendVerificationLink = () => {
      sendEmailVerificationLink();
      router.push(ROUTE_SEND_VERIFICATION_LINK);
    };

    const route = useRoute();
    const cachePathForAfterLoginRedirect = () => {
      userStore.routePathBeforeLogin = route.path;
    };

    onMounted(() => {
      if (!isAuth.value) {
        cachePathForAfterLoginRedirect();
      }
    });

    return {
      isAuth,
      description,
      resendVerificationLink,
    };
  },
};
</script>

<style scoped>
.content-container {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 80px;
}
.content-container--auth {
  row-gap: 50px;
}
.title {
  width: 315px;
}

@media (max-width: 1200px) {
  .content-container {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 80px;
  }
  .content-container--auth {
    row-gap: 50px;
  }
}
</style>
